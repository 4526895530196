<template>
  <div class="card">
    <h4>Authentication</h4>
    <div class="p-field p-grid">
      <span class="p-col-fixed" style="width:100px">Login</span>
      <div class="p-col">
        <InputText
          v-model="email"
          type="text"
        />
      </div>
    </div>
    <div class="p-field p-grid">
      <span class="p-col-fixed" style="width:100px">Password</span>
      <div class="p-col">
        <InputText
          v-model="password"
          type="password"
        />
      </div>
    </div>

    <Message
      v-if="errorMessage"
      severity="warn"
    >
      {{ errorMessage }}
    </Message>

    <Button
      :disabled="isLoading"
      type="submit"
      class="p-button-success"
      @click="onSubmit"
    >
      Login
    </Button>
  </div>
</template>

<script>
import {
  mapActions, mapState, mapGetters, mapMutations,
} from 'vuex';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';

export default {
  name: 'Authorization',
  data() {
    return {
      email: this.$store.state.email,
      password: '',
    };
  },
  components: {
    Button,
    InputText,
    Message,
  },
  computed: {
    ...mapState({
      errorMessage: (state) => state.auth.errorMessage,
      isLoading: (state) => state.auth.isLoading,
      token: (state) => state.auth.token,
      // userId: (state) => state.auth.id,
    }),
    ...mapGetters('auth', ['isAuthentication']),
  },
  created() {
    this.setError('');
  },
  methods: {
    onSubmit() {
      this.authentication({
        email: this.email,
        password: this.password,
      })
        .then(({ success }) => {
          if (!success) {
            return;
          }

          this.setCredentials(this.token);
          this.$router.push({ name: 'Home' });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    onReset() {
      this.login = '';
      this.password = '';
    },
    ...mapActions({
      authentication: 'auth/authentication',
    }),
    ...mapMutations({
      setError: 'auth/SET_ERROR',
      setCredentials: 'SET_CREDENTIALS',
    }),
  },
};
</script>

<style lang="scss" scoped>
.form {
  margin-bottom: 1rem;
}
</style>
